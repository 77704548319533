<template>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">

            <!-- Header -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-10 col-xl-10">

                    <!-- Pretitle -->
                    <h6 class="text-uppercase text-muted">Write Review</h6>

                    <!-- Title -->
                    <h1 class="mb-1" v-if="booking">
                        Review for {{booking.expertName}}
                    </h1>

                    <h4 v-if="booking" class="text-muted">For meeting on {{ booking.dateTime | dateFormat('dddd, MMM D, YYYY')}}</h4>
                   
                    <hr/>
                </div>
            </div> <!-- / .row -->
            
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-10 col-xl-10" v-if="booking">
                <div v-if="booking.reviewed">
                  <h6 class="text-muted">This meeting has already been reviewed. If you have issues or want to update the review please reach out to support@hustleforge.com</h6>
                </div>
                <div v-else>
                  <p class="mb-3">
                  <ValidationObserver ref='reviewForm'>
                    <form>
                      <validation-provider
                        #default="{ errors }"
                        name="rating"
                        rules="required|integer"
                      >
                        <h4>Rating</h4>
                        <b-form-group
                          label-for="ratings"
                          :state="errors.length > 0 ? false:null"
                        >
                          <b-form-rating
                            v-model="newRatingValue"
                            show-value
                            inline
                            no-border
                            :state="newRatingValue > 0"
                            variant="primary"
                            class="d-block mb-2"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <h4>Review</h4>
                      <h6 class="text-muted">Please try to be specific, with as much detail about the meeting as you can.</h6>
                      <validation-provider
                          #default="{ errors }"
                          name="review"
                          rules="required|min:150"
                        >
                          <b-form-group
                            label-for="Review"
                            :state="errors.length > 0 ? false:null"
                          >
                          <b-form-textarea
                            id="textarea-state"
                            v-model="reviewDescription"
                            placeholder="Write a review (min 150 characters)"
                            rows="3"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        </validation-provider>
                      </form>
                    </ValidationObserver>
                  </p>
                  <div>
                    <b-overlay
                      :show="busy"
                      rounded
                      opacity="0.4"
                      spinner-small
                      spinner-variant="primary"
                    >
                      <b-button
                        v-on:click="sumbitForm()"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        class="mt-2 mb-2"
                      >
                        Submit Review
                      </b-button>
                    </b-overlay>
                  </div>
                </div>
              </div>
            </div> 
        </div>
    </div>
</template>

<script>
import {
  BTab, 
  BTabs, 
  VBHover, 
  BForm, 
  BButton, 
  BFormTextarea, 
  BFormInput, 
  BFormGroup, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BMedia,
  BMediaAside,
  BMediaBody, 
  BLink,
  BFormFile,
  BCard,
  BCardText,
  BFormInvalidFeedback,
  BOverlay,
  BFormRating,
} from 'bootstrap-vue'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { getFirestore, getDoc, doc} from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
const functions = getFunctions()
const db = getFirestore()
const auth = getAuth()

export default {
  components: {
    BTab,
    BTabs,
    VBHover,
    BForm,
    BButton, 
    BFormTextarea,
    BFormInput, 
    BFormGroup, 
    BInputGroup, 
    BInputGroupAppend,
    BImg,
    BMediaAside,
    BMediaBody, 
    BMedia,
    BLink,
    BFormFile,
    BCardText,
    BCard,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormRating,
    required,
  },
  directives: {
    Ripple,
    'b-hover': VBHover
  },
  data() {
    return {
      reviewDescription: null,
      newRatingValue: null,
      busy: false,
      booking: null,
      username: null,
      userData: null,
    }
  },
  async created() {
    // Get the username from the route if not get the current profile
    const bookingID = (router.currentRoute.params && router.currentRoute.params.id)?router.currentRoute.params.id:null

    // Make sure we have booking ID
    if(!bookingID) this.$router.push({ name: 'error-404'})

    // Make sure we know the user
    this.userData = JSON.parse(localStorage.getItem('userData'))
    if(!this.userData) this.$router.push({ name: 'error-404'})

    // Make sure we are logged in
    this.username = (auth && auth.currentUser)?auth.currentUser.displayName:null
    if(!this.username) this.$router.push({ name: 'error-404'})

    // Check to make sure we have a booking
    const bookingRes = await getDoc(doc(db, "profiles", this.username, "bookings", bookingID))
    if(!bookingRes.exists()) this.$router.push({ name: 'error-404'})
    this.booking = bookingRes.data()

    // Make sure only the requestor can review
    if(this.booking.requestor !== this.username)this.$router.push({ name: 'error-404'})

    this.booking.bookingID = bookingRes.id
    this.booking.dateTime = this.booking.start.toDate()
  },
  methods: {
    async sumbitForm() {
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.reviewForm.validate()
      if (!validateForm) return

      this.busy = true
      const reviewedIndustries = null;//this.selectedIndustries.map(industry => industry.value) // TODO select the indusrty
      const writeExpertReview = httpsCallable(functions, 'writeExpertReview')
      const resp = await writeExpertReview({bookingID:this.booking.bookingID, rateduser:this.booking.expert, username:this.username, fullName:this.booking.requestorName, image:this.userData.image, comment:this.reviewDescription.trim(), industries:reviewedIndustries, rating:this.newRatingValue })

      console.log(resp)
      if(resp && resp.data.success){
            
        // Clear inputs for review
        this.newRatingValue = null
        this.reviewDescription = ''
        this.booking.reviewed = true

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Review Submitted`,
            icon: 'StarIcon',
            variant: 'success',
          },timeout: 5000
        })
        this.$router.push({name: 'apps-users-view', params: { id: this.booking.expert }})
        
      }else{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'New Review',
            icon: 'StarIcon',
            variant: 'danger',
            text: (resp && resp.data.message)?resp.data.message:'There was an issue processing the review, please try agian.',
          },timeout: 5000
        })
        this.busy = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

